import React from 'react'
import { connect } from 'react-redux'
import ProfileMenu from './ProfileMenu'
import styles from './style.module.scss'

@connect(({ user }) => ({ user }))
class TopBar extends React.Component {
  render() {
    const { user } = this.props
    return (
      <div className={styles.topbar}>
        <div className="mr-auto" />
        {user.companyName} &nbsp;
        <ProfileMenu />
      </div>
    )
  }
}

export default TopBar
