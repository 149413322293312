import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // System Pages
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/start',
    component: loadable(() => import('pages/user/start')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },
  {
    path: '/user/reset/:id',
    component: loadable(() => import('pages/user/reset')),
    exact: true,
  },
  {
    path: '/user/lockscreen',
    component: loadable(() => import('pages/user/lockscreen')),
    exact: true,
  },
  {
    path: '/user/verifyEmail',
    component: loadable(() => import('pages/user/verifyEmail')),
    exact: true,
  },
  {
    path: '/dispatch',
    component: loadable(() => import('pages/dispatch')),
    exact: true,
  },

  {
    path: '/driversMap',
    component: loadable(() => import('pages/driversMap')),
    exact: true,
  },

  {
    path: '/hackneyDriversMap',
    component: loadable(() => import('pages/hackneyDriversMap')),
    exact: true,
  },

  {
    path: '/airportStatus',
    component: loadable(() => import('pages/airportStatus')),
    exact: true,
  },

  {
    path: '/trips/tripsList',
    component: loadable(() => import('pages/trips/tripsList')),
    exact: true,
  },

  {
    path: '/trips/hackneyTripsList',
    component: loadable(() => import('pages/trips/hackneyTripsList')),
    exact: true,
  },

  {
    path: '/trips/upcoming',
    component: loadable(() => import('pages/trips/upcoming')),
    exact: true,
  },

  {
    path: '/trips/tripsActiveList',
    component: loadable(() => import('pages/trips/tripsActiveList')),
    exact: true,
  },

  {
    path: '/drivers/driversList',
    component: loadable(() => import('pages/drivers/driversList')),
    exact: true,
  },

  {
    path: '/drivers/payouts/:id',
    component: loadable(() => import('pages/drivers/payouts')),
    exact: true,
  },

  {
    path: '/drivers/payouts',
    component: loadable(() => import('pages/drivers/payouts')),
    exact: true,
  },

  {
    path: '/drivers/driver/:id/edit',
    component: loadable(() => import('pages/drivers/editDriver')),
    exact: true,
  },

  {
    path: '/drivers/driver/:id',
    component: loadable(() => import('pages/drivers/viewDriver')),
    exact: true,
  },

  {
    path: '/drivers/driverRoute/:id',
    component: loadable(() => import('pages/drivers/driverRoute')),
    exact: true,
  },

  {
    path: '/drivers/driversAthenaList',
    component: loadable(() => import('pages/drivers/driversAthenaList')),
    exact: true,
  },

  {
    path: '/drivers/deletedDriversList',
    component: loadable(() => import('pages/drivers/deletedDriversList')),
    exact: true,
  },

  {
    path: '/users/usersList',
    component: loadable(() => import('pages/users/usersList')),
    exact: true,
  },

  {
    path: '/users/user/:id/edit',
    component: loadable(() => import('pages/users/editUser')),
    exact: true,
  },

  {
    path: '/users/user/:id',
    component: loadable(() => import('pages/users/viewUser')),
    exact: true,
  },

  {
    path: '/users/doormanUsersList',
    component: loadable(() => import('pages/users/doormanUsersList')),
    exact: true,
  },

  {
    path: '/users/deletedUsersList',
    component: loadable(() => import('pages/users/deletedUsersList')),
    exact: true,
  },

  {
    path: '/trips/tripDetails/:id',
    component: loadable(() => import('pages/trips/tripDetails')),
    exact: true,
  },

  {
    path: '/trips/hackneyTripDetails/:id',
    component: loadable(() => import('pages/trips/hackneyTripDetails')),
    exact: true,
  },

  {
    path: '/promo/promoList',
    component: loadable(() => import('pages/promo/promoList')),
    exact: true,
  },

  {
    path: '/promo/promoCode/:id',
    component: loadable(() => import('pages/promo/addPromoCode')),
    exact: true,
  },

  {
    path: '/promo/promoCode',
    component: loadable(() => import('pages/promo/addPromoCode')),
    exact: true,
  },

  {
    path: '/fleets/fleetsList',
    component: loadable(() => import('pages/fleets/fleetsList')),
    exact: true,
  },

  {
    path: '/fleets/athenaFleetsList',
    component: loadable(() => import('pages/fleets/athenaFleetsList')),
    exact: true,
  },

  {
    path: '/fleets/viewFleet/:id',
    component: loadable(() => import('pages/fleets/viewFleet')),
    exact: true,
  },

  {
    path: '/dashboard',
    component: loadable(() => import('pages/dashboard/alpha')),
    exact: true,
  },

  {
    path: '/logout',
    component: loadable(() => import('pages/user/logout')),
    exact: true,
  },

  {
    path: '/account/newRequest',
    component: loadable(() => import('pages/newRequest/newRequest')),
  },
  {
    path: '/account/statements',
    component: loadable(() => import('pages/statements')),
    exact: true,
  },
  {
    path: '/account/members',
    component: loadable(() => import('pages/members/members')),
    exact: true,
  },
  {
    path: '/account/addMembers/:id',
    component: loadable(() => import('pages/members/addMembers')),
    exact: true,
  },
  {
    path: '/account/addMembers',
    component: loadable(() => import('pages/members/addMembers')),
    exact: true,
  },
  {
    path: '/account/companyAdmins',
    component: loadable(() => import('pages/companyAdmin/companyAdmins')),
  },
  {
    path: '/account/addCompanyAdmins/:id',
    component: loadable(() => import('pages/companyAdmin/addCompanyAdmins')),
  },
  {
    path: '/account/addCompanyAdmins',
    component: loadable(() => import('pages/companyAdmin/addCompanyAdmins')),
  },
  {
    path: '/account/groups',
    component: loadable(() => import('pages/groups/groups')),
    exact: true,
  },
  {
    path: '/account/createGroup/:id',
    component: loadable(() => import('pages/groups/createGroup')),
    exact: true,
  },
  {
    path: '/account/createGroup',
    component: loadable(() => import('pages/groups/createGroup')),
    exact: true,
  },
  {
    path: '/account/activity',
    component: loadable(() => import('pages/activites/activity')),
    exact: true,
  },
  {
    path: '/account/activityDetail/:id',
    component: loadable(() => import('pages/activites/activityDetail')),
    exact: true,
  },
  {
    path: '/account/settings',
    component: loadable(() => import('pages/settings/information')),
    exact: true,
  },
  {
    path: '/account/editProfile/:id',
    component: loadable(() => import('pages/editProfile')),
  },
  {
    path: '/concierge/newRequest',
    component: loadable(() => import('pages/newRequest/newRequest')),
    exact: true,
  },
  {
    path: '/concierge/tripDetails/:id/:from',
    component: loadable(() => import('pages/tripHistory/tripDetails')),
    exact: true,
  },
  {
    path: '/concierge/tripDetails/:id',
    component: loadable(() => import('pages/tripHistory/tripDetails')),
    exact: true,
  },
  {
    path: '/concierge/tripsList',
    component: loadable(() => import('pages/tripHistory/tripsList')),
    exact: true,
  },
  {
    path: '/concierge/activeRides',
    component: loadable(() => import('pages/allRides')),
    exact: true,
  },
  {
    path: '/admin/adminList',
    component: loadable(() => import('pages/admin/adminList')),
    exact: true,
  },
  {
    path: '/admin/addAdmin/:id',
    component: loadable(() => import('pages/admin/addAdmin')),
    exact: true,
  },
  {
    path: '/admin/addAdmin/',
    component: loadable(() => import('pages/admin/addAdmin')),
    exact: true,
  },
  {
    path: '/company/companyList',
    component: loadable(() => import('pages/company/companyList')),
    exact: true,
  },
  {
    path: '/company/addCompany/:id',
    component: loadable(() => import('pages/company/addCompany')),
  },
  {
    path: '/company/addCompany/',
    component: loadable(() => import('pages/company/addCompany')),
    exact: true,
  },
  {
    path: '/vehiclesBoston',
    component: loadable(() => import('pages/VehiclesBoston/vehiclesBostonList')),
    exact: true,
  },
  {
    path: '/vehiclesBoston/:id',
    component: loadable(() => import('pages/VehiclesBoston/addVehicleBoston')),
  },
  {
    path: '/vehiclesBostonAdd',
    component: loadable(() => import('pages/VehiclesBoston/addVehicleBoston')),
    exact: true,
  },
  {
    path: '/driversBoston',
    component: loadable(() => import('pages/DriversBoston/driversBostonList')),
    exact: true,
  },
  {
    path: '/driversBoston/:id',
    component: loadable(() => import('pages/DriversBoston/addDriverBoston')),
  },
  {
    path: '/driversBostonAdd',
    component: loadable(() => import('pages/DriversBoston/addDriverBoston')),
    exact: true,
  },
  {
    path: '/fleets/medallion',
    component: loadable(() => import('pages/fleets/medallion')),
    exact: true,
  },
  {
    path: '/fleets/owner/ownerList',
    component: loadable(() => import('pages/fleets/owner/ownerList')),
    exact: true,
  },
  {
    path: '/fleets/owner/ownerDetail/:id',
    component: loadable(() => import('pages/fleets/owner/ownerDetail')),
    exact: true,
  },
  {
    path: '/fleets/fleets/fleetsList',
    component: loadable(() => import('pages/fleets/fleets/fleetsList')),
    exact: true,
  },
  {
    path: '/fleets/fleets/fleetsDetail/:id',
    component: loadable(() => import('pages/fleets/fleets/fleetsDetail')),
    exact: true,
  },

  {
    path: '/meter/tripsList',
    component: loadable(() => import('pages/meter/tripsList')),
    exact: true,
  },
  {
    path: '/meter/tripDetails/:id',
    component: loadable(() => import('pages/meter/tripDetails')),
    exact: true,
  },
  {
    path: '/meter/vehicles',
    component: loadable(() => import('pages/meter/vehicles/list')),
    exact: true,
  },
  {
    path: '/meter/vehicles/:id',
    component: loadable(() => import('pages/meter/vehicles/details')),
    exact: true,
  },
  {
    path: '/meter/meters',
    component: loadable(() => import('pages/meter/meters/list')),
    exact: true,
  },
  {
    path: '/meter/meters/:id',
    component: loadable(() => import('pages/meter/meters/details')),
    exact: true,
  },
  {
    path: '/meter/obds',
    component: loadable(() => import('pages/meter/obds/list')),
    exact: true,
  },
  {
    path: '/meter/obds/:id',
    component: loadable(() => import('pages/meter/obds/details')),
    exact: true,
  },
  {
    path: '/meter/printers',
    component: loadable(() => import('pages/meter/printers/list')),
    exact: true,
  },
  {
    path: '/meter/printers/:id',
    component: loadable(() => import('pages/meter/printers/details')),
    exact: true,
  },
  {
    path: '/meter/backseattablets',
    component: loadable(() => import('pages/meter/backseattablets/list')),
    exact: true,
  },
  {
    path: '/meter/backseattablets/:id',
    component: loadable(() => import('pages/meter/backseattablets/details')),
    exact: true,
  },
  {
    path: '/meter/vehiclesMap',
    component: loadable(() => import('pages/meter/vehicles/map')),
    exact: true,
  },
  {
    path: '/meter/tolls',
    component: loadable(() => import('pages/meter/tolls/list')),
    exact: true,
  },
  {
    path: '/meter/tolls/add/:id',
    component: loadable(() => import('pages/meter/tolls/add')),
    exact: true,
  },
  {
    path: '/meter/tolls/add',
    component: loadable(() => import('pages/meter/tolls/add')),
    exact: true,
  },
  {
    path: '/meter/tolls/:id',
    component: loadable(() => import('pages/meter/tolls/details')),
    exact: true,
  },
  {
    path: '/meter/fares',
    component: loadable(() => import('pages/meter/fares/list')),
    exact: true,
  },
  {
    path: '/meter/fares/add/:id',
    component: loadable(() => import('pages/meter/fares/add')),
    exact: true,
  },
  {
    path: '/meter/fares/add',
    component: loadable(() => import('pages/meter/fares/add')),
    exact: true,
  },
  {
    path: '/meter/fares/:id',
    component: loadable(() => import('pages/meter/fares/details')),
    exact: true,
  },
  {
    path: '/meter/servicefee',
    component: loadable(() => import('pages/meter/fares/servicefee/list')),
    exact: true,
  },
  {
    path: '/meter/servicefee/add/:id',
    component: loadable(() => import('pages/meter/fares/servicefee/add')),
    exact: true,
  },
  {
    path: '/meter/servicefee/add',
    component: loadable(() => import('pages/meter/fares/servicefee/add')),
    exact: true,
  },
  {
    path: '/meter/servicefee/:id',
    component: loadable(() => import('pages/meter/fares/servicefee/details')),
    exact: true,
  },
  {
    path: '/meter/farefixedsurcharges',
    component: loadable(() => import('pages/meter/fares/fixedsurcharges/list')),
    exact: true,
  },
  {
    path: '/meter/farefixedsurcharges/add/:id',
    component: loadable(() => import('pages/meter/fares/fixedsurcharges/add')),
    exact: true,
  },
  {
    path: '/meter/farefixedsurcharges/add',
    component: loadable(() => import('pages/meter/fares/fixedsurcharges/add')),
    exact: true,
  },
  {
    path: '/meter/farefixedsurcharges/:id',
    component: loadable(() => import('pages/meter/fares/fixedsurcharges/details')),
    exact: true,
  },
  {
    path: '/meter/faretimesurcharges',
    component: loadable(() => import('pages/meter/fares/timesurcharges/list')),
    exact: true,
  },
  {
    path: '/meter/faretimesurcharges/add/:id',
    component: loadable(() => import('pages/meter/fares/timesurcharges/add')),
    exact: true,
  },
  {
    path: '/meter/faretimesurcharges/add',
    component: loadable(() => import('pages/meter/fares/timesurcharges/add')),
    exact: true,
  },
  {
    path: '/meter/faretimesurcharges/:id',
    component: loadable(() => import('pages/meter/fares/timesurcharges/details')),
    exact: true,
  },
  {
    path: '/meter/payouts',
    component: loadable(() => import('pages/meter/payouts')),
    exact: true,
  },
  {
    path: '/debug/gpsLocations',
    component: loadable(() => import('pages/meter/debug/gpsLocations')),
    exact: true,
  },
  {
    path: '/pelias',
    component: loadable(() => import('pages/pelias/newRequest')),
    exact: true,
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/trips/hackneyTripsList" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
