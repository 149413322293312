import actions from './actions'

const initialState = {
  menuLeftData: [],
  masterDispatchmenuLeftData: [],
  hackneyLeftMenuData: [],
  corporateLeftMenuData: [],
  corporateOperatorLeftMenuData: [],
  conciergeLeftMenuData: [],
  emptyLeftMenuData: [],
  menuTopData: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
