import { all, put, call } from 'redux-saga/effects'
import {
  getLeftMenuData,
  getTopMenuData,
  getCorporateLeftMenuData,
  getCorporateOperatorLeftMenuData,
  getConciergeLeftMenuData,
  getEmptyLeftMenuData,
  getHackneyLeftMenuData,
  getMasterDispatchLeftMenuData
} from 'services/menu'

export function* GET_DATA() {
  const menuLeftData = yield call(getLeftMenuData)
  const masterDispatchmenuLeftData = yield call(getMasterDispatchLeftMenuData)
  const hackneyLeftMenuData = yield call(getHackneyLeftMenuData)
  const corporateLeftMenuData = yield call(getCorporateLeftMenuData)
  const corporateOperatorLeftMenuData = yield call(getCorporateOperatorLeftMenuData)
  const conciergeLeftMenuData = yield call(getConciergeLeftMenuData)
  const emptyLeftMenuData = yield call(getEmptyLeftMenuData)
  const menuTopData = yield call(getTopMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuLeftData,
      hackneyLeftMenuData,
      corporateLeftMenuData,
      corporateOperatorLeftMenuData,
      conciergeLeftMenuData,
      emptyLeftMenuData,
      menuTopData,
      masterDispatchmenuLeftData
    },
  })
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
