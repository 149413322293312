export async function getLeftMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fa fa-pie-chart',
      url: '/dashboard',
    },
    {
      title: 'Drivers Map',
      key: 'driversMap',
      icon: 'fa fa-map',
      url: '/driversMap',
    },
    {
      title: 'Rides',
      key: 'trips',
      icon: 'fa fa-road',
      children: [
        {
          key: 'tripsList',
          title: 'All Rides',
          url: '/trips/tripsList',
        },
        {
          key: 'upcoming',
          title: 'Upcoming',
          url: '/trips/upcoming',
        },
        {
          key: 'active',
          title: 'ActiveRides',
          url: '/trips/tripsActiveList',
        },
        {
          key: 'hackneyTripsList',
          title: 'Hackney Rides',
          url: '/trips/hackneyTripsList',
        },
      ],
    },
    {
      title: 'Drivers',
      key: 'drivers',
      icon: 'fa fa-tachometer',
      children: [
        {
          key: 'driversList',
          title: 'Drivers List',
          url: '/drivers/driversList',
        },
        {
          key: 'driversPayouts',
          title: 'Drivers Payouts',
          url: '/drivers/payouts',
        },
        {
          key: 'driversPayoutsRequested',
          title: 'Drivers Requested Payouts',
          url: '/drivers/payouts/requested',
        },
        {
          key: 'driversAthenaList',
          title: 'Athena List',
          url: '/drivers/driversAthenaList',
        },
        {
          key: 'deletedDrivers',
          title: 'Deleted Drivers',
          url: '/drivers/driversAthenaList',
        },
      ],
    },
    {
      title: 'Users',
      key: 'users',
      icon: 'fa fa-user',
      children: [
        {
          key: 'usersList',
          title: 'Users List',
          url: '/users/usersList',
        },
        {
          key: 'doormanUsersList',
          title: 'Doorman Users',
          url: '/users/doormanUsersList',
        },
        {
          key: 'deletedUsersList',
          title: 'Deleted Users',
          url: '/users/deletedUsersList',
        },
      ],
    },
    {
      title: 'Notification',
      key: 'airportStatus',
      icon: 'fa fa-map-marker',
      url: '/airportStatus',
    },
    {
      title: 'Fleets',
      key: 'fleets',
      icon: 'fa fa-automobile',
      children: [
        {
          key: 'medallion',
          title: 'Medallion',
          url: '/fleets/medallion',
        },
        {
          key: 'owner',
          title: 'Owner',
          url: '/fleets/owner/ownerList',
        },
        {
          key: 'fleets',
          title: 'Fleets',
          url: '/fleets/fleets/fleetsList',
        },
        // {
        //   key: 'fleetsList',
        //   title: 'Fleets List',
        //   url: '/fleets/fleetsList',
        // },
        // {
        //   key: 'athenaFleetsList',
        //   title: 'Athena Fleets List',
        //   url: '/fleets/athenaFleetsList',
        // },
      ],
    },
    {
      title: 'Promo Codes',
      key: 'promo',
      icon: 'fa fa-gift',
      children: [
        {
          key: 'promoList',
          title: 'Promo Codes List',
          url: '/promo/promoList',
        },
        {
          key: 'promoCode',
          title: 'Add Promo Codes',
          url: '/promo/promoCode',
        },
      ],
    },
    {
      title: 'Dispatch',
      key: 'dispatch',
      icon: 'fa fa-desktop',
      url: '/dispatch',
    },
    {
      title: 'Account',
      key: 'account',
      icon: 'icmn-address-book',
      children: [
        {
          key: 'newRequest',
          title: 'New Request',
          url: '/account/newRequest',
        },
        {
          key: 'activeRides',
          title: 'All Rides',
          url: '/concierge/activeRides',
        },
        {
          key: 'members',
          title: 'Members',
          url: '/account/members',
        },
        {
          key: 'companyAdmins',
          title: 'Company Admins',
          url: '/account/companyAdmins',
        },
        {
          key: 'groups',
          title: 'Groups',
          url: '/account/groups',
        },
        {
          key: 'activity',
          title: 'Activity',
          url: '/account/activity',
        },
        {
          key: 'statements',
          title: 'Statements',
          url: '/account/statements',
        },
        {
          key: 'setting',
          title: 'Settings',
          url: '/account/settings',
        },
      ],
    },
    {
      title: 'Concierge',
      key: 'concierge',
      icon: 'icmn-user-tie',
      children: [
        {
          key: 'newRequest',
          title: 'New Request',
          url: '/concierge/newRequest',
        },
        {
          key: 'activeRides',
          title: 'All Rides',
          url: '/concierge/activeRides',
        },
        {
          key: 'tripHistory',
          title: 'Trip History',
          url: '/concierge/tripsList',
        },
      ],
    },
    {
      title: 'Admin',
      key: 'admin',
      url: '/admin/adminList',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    },
    {
      title: 'Company',
      key: 'company',
      url: '/company/companyList',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Settings',
      key: 'settings',
    },
    {
      title: 'Vehicles Boston',
      key: 'vehiclesBoston',
      url: '/vehiclesBoston',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Drivers Boston',
      key: 'driversBoston',
      url: '/driversBoston',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Pelias',
      key: 'pelias',
      url: '/pelias',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Meter',
      key: 'meter',
      icon: 'fa fa-road',
      children: [
        {
          key: 'vehiclesMap',
          title: 'Vehicles Map',
          url: '/meter/vehiclesMap',
        },
        {
          key: 'tripsList',
          title: 'All Rides',
          url: '/meter/tripsList',
        },
        {
          key: 'vehiclesList',
          title: 'All Vehicles',
          url: '/meter/vehicles',
        },
        {
          key: 'metersList',
          title: 'All Meters',
          url: '/meter/meters',
        },
        {
          key: 'obdsList',
          title: 'All OBDs',
          url: '/meter/obds',
        },
        {
          key: 'printersList',
          title: 'All Printers',
          url: '/meter/printers',
        },
        {
          key: 'backseatTabletsList',
          title: 'All BackseatTablets',
          url: '/meter/backseattablets',
        },
        {
          key: 'tollsList',
          title: 'All Tolls',
          url: '/meter/tolls',
        },
        {
          key: 'faresList',
          title: 'All Fares',
          url: '/meter/fares',
        },
        {
          key: 'payouts',
          title: 'Driver payouts',
          url: '/meter/payouts',
        },
      ],
    },
    {
      title: 'Debug',
      key: 'debug',
      icon: 'fa fa-road',
      children: [
        {
          key: 'gpsLocations',
          title: 'Gps Locations',
          url: '/debug/gpsLocations',
        },
      ],
    }
  ]
}

export async function getMasterDispatchLeftMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fa fa-pie-chart',
      url: '/dashboard',
    },
    {
      title: 'Drivers Map',
      key: 'driversMap',
      icon: 'fa fa-map',
      url: '/driversMap',
    },
    {
      title: 'Concierge',
      key: 'concierge',
      icon: 'icmn-user-tie',
      children: [
        {
          key: 'newRequest',
          title: 'New Request',
          url: '/concierge/newRequest',
        },
        {
          key: 'activeRides',
          title: 'All Rides',
          url: '/concierge/activeRides',
        },
        {
          key: 'tripHistory',
          title: 'Trip History',
          url: '/concierge/tripsList',
        },
      ],
    },
    {
      title: 'Settings',
      key: 'settings',
    },
  ]
}

export async function getCorporateLeftMenuData() {
  return [
    {
      key: 'newRequest',
      title: 'New Request',
      url: '/account/newRequest',
      icon: 'fa fa-desktop',
    },
    {
      key: 'activeRides',
      title: 'All Rides',
      url: '/concierge/activeRides',
      icon: 'fa fa-road',
    },
    {
      key: 'companyAdmins',
      title: 'Company Admins',
      url: '/account/companyAdmins',
      icon: 'fa fa-user',
    },
    {
      key: 'members',
      title: 'Members',
      url: '/account/members',
      icon: 'fa fa-group',
    },
    // {
    //   key: 'groups',
    //   title: 'Groups',
    //   url: '/account/groups',
    //   icon: 'fa fa-group',
    // },

    {
      key: 'activity',
      title: 'Activity',
      url: '/concierge/tripsList',
      icon: 'icmn-address-book',
    },
    {
      key: 'setting',
      title: 'Settings',
      url: '/account/settings',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    },
    {
      title: 'Instructions',
      key: 'instructions',
      icon: 'fa fa-question-circle',
      children: [
        {
          key: 'account',
          title: 'Account',
          url:
            'https://waave-prod-api.us-east-1.elasticbeanstalk.com/pdf/Waave_Business_Account_Instructions.pdf',
          target: '_blank',
        },
        {
          key: 'request',
          title: 'Request',
          url:
            'https://waave-prod-api.us-east-1.elasticbeanstalk.com/pdf/How_to_request_a_ride.pdf',
          target: '_blank',
        },
      ],
    },
  ]
}

export async function getCorporateOperatorLeftMenuData() {
  return [
    {
      key: 'newRequest',
      title: 'New Request',
      url: 'https://health.waave.co/#/request',
      target: '_self',
      icon: 'fa fa-desktop',
    },
    {
      key: 'activeRides',
      title: 'All Rides',
      url: '/concierge/activeRides',
      icon: 'fa fa-road',
    },
    {
      key: 'activity',
      title: 'Activity',
      url: '/concierge/tripsList',
      icon: 'icmn-address-book',
    },
  ]
}

export async function getHackneyLeftMenuData() {
  return [
    {
      title: 'Drivers map',
      key: 'hackneyDriversMap',
      icon: 'fa fa-map',
      url: '/hackneyDriversMap',
    },
    {
      key: 'hackneyTripsList',
      title: 'Rides',
      icon: 'fa fa-road',
      url: '/trips/hackneyTripsList',
    },
    {
      key: 'tripsList',
      url: '/meter/tripsList',
      title: 'Meter Rides',
      icon: 'fa fa-road',
    },
    {
      title: 'Vehicles Boston',
      key: 'vehiclesBoston',
      url: '/vehiclesBoston',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Drivers Boston',
      key: 'driversBoston',
      url: '/driversBoston',
      icon: 'icmn icmn-home',
    },
  ]
}

export async function getConciergeLeftMenuData() {
  return [
    {
      key: 'newRequest',
      title: 'New Request',
      url: '/concierge/newRequest',
      icon: 'fa fa-desktop',
    },
    {
      key: 'activeRides',
      title: 'All Rides',
      url: '/concierge/activeRides',
      icon: 'fa fa-road',
    },
    {
      key: 'tripHistory',
      title: 'Trip History',
      url: '/concierge/tripsList',
      icon: 'icmn-address-book',
    },
    {
      title: 'Settings',
      key: 'settings',
    },
  ]
}

export async function getEmptyLeftMenuData() {
  return []
}

export async function getTopMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fa fa-pie-chart',
      url: '/dashboard',
    },
    {
      title: 'Drivers map',
      key: 'driversMap',
      icon: 'fa fa-map',
      url: '/driversMap',
    },
    {
      title: 'Trips',
      key: 'trips',
      icon: 'fa fa-road',
      children: [
        {
          key: 'tripsList',
          title: 'Trips List',
          url: '/trips/tripsList',
        },
      ],
    },
    {
      title: 'Drivers',
      key: 'drivers',
      icon: 'fa fa-tachometer',
      children: [
        {
          key: 'driversList',
          title: 'Drivers List',
          url: '/drivers/driversList',
        },
        {
          key: 'driversAthenaList',
          title: 'Athena List',
          url: '/drivers/driversAthenaList',
        },
      ],
    },
    {
      title: 'Users',
      key: 'users',
      icon: 'fa fa-user',
      children: [
        {
          key: 'usersList',
          title: 'Users List',
          url: '/users/usersList',
        },
        {
          key: 'doormanUsersList',
          title: 'Doorman Users',
          url: '/users/doormanUsersList',
        },
        {
          key: 'deletedUsersList',
          title: 'Deleted Users',
          url: '/users/deletedUsersList',
        },
      ],
    },
    {
      title: 'Notification',
      key: 'airportStatus',
      icon: 'fa fa-map-marker',
      url: '/airportStatus',
    },
    {
      title: 'Fleets',
      key: 'fleets',
      icon: 'fa fa-automobile',
      children: [
        {
          key: 'fleetsList',
          title: 'Fleets List',
          url: '/fleets/fleetsList',
        },
        {
          key: 'athenaFleetsList',
          title: 'Athena Fleets List',
          url: '/fleets/athenaFleetsList',
        },
      ],
    },
    {
      title: 'Promo Codes',
      key: 'promo',
      icon: 'fa fa-gift',
      children: [
        {
          key: 'promoList',
          title: 'Promo Codes List',
          url: '/promo/promoList',
        },
        {
          key: 'promoCode',
          title: 'Add Promo Codes',
          url: '/promo/promoCode',
        },
      ],
    },
    {
      title: 'Dispatch',
      key: 'dispatch',
      icon: 'fa fa-desktop',
      url: '/dispatch',
    },
    {
      title: 'Settings',
      key: 'settings',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    },
    {
      title: 'Account',
      key: 'account',
      icon: 'icmn-address-book',
      children: [
        {
          key: 'members',
          title: 'Members',
          url: '/account/members',
        },
        {
          key: 'groups',
          title: 'Groups',
          url: '/account/groups',
        },
        {
          key: 'activity',
          title: 'Activity',
          url: '/account/activity',
        },
        {
          key: 'statements',
          title: 'Statements',
          url: '/account/statements',
        },
        {
          key: 'setting',
          title: 'Settings',
          url: '/account/settings',
        },
      ],
    },
    {
      title: 'Concierge',
      key: 'concierge',
      icon: 'icmn-user-tie',
      children: [
        {
          key: 'newRequest',
          title: 'New Request',
          url: '/concierge/newRequest',
        },
        {
          key: 'activeRides',
          title: 'All Rides',
          url: '/concierge/activeRides',
        },
        {
          key: 'tripHistory',
          title: 'Trip History',
          url: '/concierge/tripsList',
        },
      ],
    },
  ]
}
